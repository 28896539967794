/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
strong{
  font-weight: bold;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


@media (max-width: 600px){
h1,h2,h3,h4,h5,h6,p,span{
 text-align: center;
}
}

/* =============== Começa aqui ============ */

h1,h2,h3,h4{
  font-family: 'PockotaBold';
}

body,button, p,span{
  font-family: 'PockotaReg'
}

body.menu-open{
  overflow: hidden;
}

h2,h3{
	font-size: 2em;
	color: #ed272a;
	margin: 1em
}

.home_theme h2{
  font-size: 1.4em;
}
@media (min-width:480px){
  .home_theme h2{
    font-size: 2em;
  }
}

.home_theme .hero{
  margin:2em auto;
}



.button__theme{
  cursor: pointer;
  font-size: 1.05em;
  margin-top: 1em;
  padding: 0.3125em 1.25em;
  border-radius: 1em;
  background: #ed272a;
  border: 0;
  color: #fff;
  height: 50px;
  font-family: "PockotaBold";
}
.button__theme--light{
  cursor: pointer;
  font-size: 1.05em;
  margin-top: 1em;
  padding: 0.3125em 1.25em;
  border-radius: 1em;
  background: #ed272a;
  border: 0;
  color: #fff;
  height: 50px;
  font-family: "PockotaBold";
}

.button__theme>a ,
.button__theme>span ,
.button__theme--light>a,
.button__theme--light>span
 {
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	height: 100%;
    text-decoration: none;
    font-family: "PockotaBold";
    font-size: 1em;
    color: #fff;
    text-transform: uppercase;
}

.button__theme>a span ,
.button__theme--light>a span {
	font-family: "PockotaBold";
	font-size: 1em;
	color: #fff;
	text-transform: uppercase;
}

input[type="submit"].button__theme{
    cursor: pointer;
    font-size: 1.05em;
    margin-top: 1em;
    padding: 0.3125em 1.25em;
    border-radius: 1em;
    background: #ed272a;
    border: 0;
    color: #fff;
    height: 50px;
    transition: all 0.3s;
}


/*
1. menu


*/



/* menu */

.wrapper{
  margin-top: 5em;
}

.abovemenu{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #ed272a;
  color: #fff;
  font-family: Amplesoft;
  z-index: 8;
}

.abovemenu p {
  font-size: 0.75em;
  padding: 5px;
}

@media (min-width:650px){
  .abovemenu p {
    font-size:0.9em;
  }
}

.menu {
  background-color: #fff;
}

.menu .opened{
  overflow: hidden;
}

.navegacao {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  background: #ed272a;
  box-shadow: 0px -10px 15px #000;
}

.navegacao:before{
  content: "";
  display: table;
  table-layout: fixed;

}
.navegacao:after{
  content: "";
  display: table;
  table-layout: fixed;

}

.navegacao:after{
  clear: both;
}

.navegacao__menus {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 250px;
  justify-content: space-around;
  margin: 0;

}

.item-inline a{
  text-transform: uppercase;
  font-family: PockotaReg;
  color: #fff;
  transition: 0.3s all;
}


.item-inline a:hover{
  font-family: PockotaReg;
  color: #fff;
}

.navegacao__logo{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}


.navegacao__logo a{
  width: 100%;

}

.navegacao__logo a img{
  width: 150px;
  margin: 10px auto;

}

.itens__hamburger{
  height: 0;
  opacity: 0;
  transition: opacity 1s;
  display: none;
}

.itens__hamburger .menu-item{
  visibility: hidden;
}

.itens__hamburger.opened{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(255,255,255,0.8);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 12;
  overflow: hidden;
}

.itens__hamburger.opened a{
  color: #ffffff;
  text-decoration: none;
}

.itens__hamburger.opened .menu-item{
  visibility: visible;
}

.filler{
  background: rgba(255,255,255,0.8);
  height: 10vh
}

.menu-item{
  margin: 0.5em auto;
  background: #ed272a;
  padding: 4px 8px;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  font-size: 1.45em;
  border: 2px solid #ed272a ;
  box-shadow: 0 0 3px #fff;
}
.menu-item:hover {
  border: 2px solid #fff ;
  box-shadow: 0 0 3px #666;

}


@media (min-height:560px){
  .menu-item{
    margin: 1em auto;
    background: #ed272a;
    padding: 10px 20px;
    width: 200px;
    border-radius: 20px;
    text-align: center;
    font-size: 1.45em;
  }
}

.menu-item a{
  text-decoration: none;
  color: #fff;
}


.icon a img {
  max-width: 35px;
  object-fit: contain;
  object-position: center;

}

div.hamburger{
    cursor: pointer;
    width: 2.1875em;
    display: flex;
    flex-flow: column nowrap
}
div.hamburger span{
  width: 2.1875em;
  height: 0.5em;
  background: #fff;
}

div.hamburger::after,
div.hamburger::before{
  content: "";
  background: #fff;
  width: 2.1875em;
  height: 0.5em;
  margin: 0.1875em 0;
}

/* hero */
.hero.masthead{
  margin: 0;
  height: 65vh;
  overflow: hidden;
}

.hero.masthead .hero__container{
  height: 100%;
}

@media (min-width: 420px) {
  .hero.masthead{
    height: 100vh;
  }
}


.masthead .hero__container .cta-h{
  max-width: 90%;
  order: 1;
  text-align: center;
}
.masthead .hero__container .cta-h h2 {
  color: #ffffff;
  font-size: 2.5em;
  margin: 0 auto;
  margin-bottom: 1em;
}
.masthead .hero__container .img-h{
  max-width: 90%;
}

.masthead .container__text--white h2{
  font-size: 2em!important;
}

@media ( min-width: 420px){
  .masthead .hero__container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  .masthead .hero__container .cta-h{
    max-width: 90%;
  }
  .masthead .hero__container .cta-h h2 {
    font-size: 2em;
  }

  .masthead .container__text--white h2{
    font-size: 2.5em!important;
  }

}

@media (min-width: 900px) {
  .masthead .hero__container .cta-h{
    max-width: 40%;
  }
  .masthead .hero__container .cta-h h2 {
    font-size: 4em;
  }
  .masthead .container__text--white h2{
    font-size: 4em!important;
  }


}
.masthead .cta__image{
  flex: 1 1 auto;
  justify-content: right;
}
.masthead .cta__containertext{
  flex: 1 1 auto;
}

@media  (min-width: 875px) {
  .masthead .cta__containertext{
    flex: 1 1 50%;
  }
}
@media (max-width: 600px){
  .masthead .cta__image{
    display: none;
  }
}


.hero__container {
  z-index: 7;
  height: 100vh;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
}


.hero__container img{
  max-width: 100%;
  object-fit: contain;
  object-position: center;

}

.home_theme .hero.firstsec{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 4em 0;
  margin: 0 0 1em 0;
}


.home_theme .hero.firstsec .cta__containertext .container__text--white,
.home_theme .hero.firstsec .cta__containertext .container__text--white p,
.home_theme .hero.firstsec .cta__containertext .container__text--white h2{
  text-align: center;
}
.home_theme .hero.firstsec .cta__containertext .container__text--white h2{
  margin: 0 0 1em 0;
}
@media (min-width: 520px) {
  .home_theme .hero.firstsec{
    padding: 7em 0;
    margin: 0 0 2em 0;
  }
  .home_theme .hero.firstsec .cta__containertext .container__text--white h2{
    margin: 0 0 2em 0;
  }
}


.home_theme .left-brush{
  display: none;
}

.home_theme .right-brush{
  display: none;
}


@media (min-width: 900px){
  .home_theme .left-brush{
    display: flex;
    flex: 25% 1;
    justify-content: center;
    /* position: absolute;
    left:0; */
  }

  .home_theme .right-brush{
    display: flex;
    flex: 25% 1;
    justify-content: center;
    /* position: absolute;
    bottom: 0;
    right: 0; */
  }
  .home_theme .principal{
    display: initial;
    flex:30%;
  }

  .home_theme .left-brush img{
    transform: rotate(-5deg);
    max-width: 300px;
  
  }
  .home_theme .right-brush img{
    transform: rotate(10deg);
    max-width: 300px;
  }


  .home_theme .masthead .left-brush{
    display: initial;
    position: absolute;
    left:-85px;
    bottom: 0;
    max-width:400px;
  }

  .home_theme .masthead .right-brush{
    display: initial;
    position: absolute;
    bottom: 0;
    right: -85px;
    max-width:365px;
  }
  .home_theme .masthead .left-brush img{
    transform: rotate(20deg) scale(1.5);
    max-width: 300px;
  
  }
  .home_theme .masthead .right-brush img{
    transform: rotate(-20deg);
    max-width: 300px;
  }




}



.container__titulo{
  margin: 1em 0;
color: #ed272a;

}
.container__titulo h2{
  font-size: 2em;

}

/* call to action */



.cta__{
  display: flex;
  flex-flow: column nowrap;
}

@media (min-width: 820px) {
  .cta__{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}


.cta__--white{
  height: 30vh;
  display: flex;
  flex-flow: row nowrap;
  background: #fff;
}




.cta__containertext.home--container{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cta__containertext.home--container h2{
  margin: 1em;
  margin-bottom:0;
}




@media (min-width:480px){
  .cta__containertext.home--container h2{
    margin: 1em;
  }


  .cta__containertext{
    letter-spacing: 1px;
    line-height: 1.5em;
    box-shadow: initial;
  }

  .cta__containertext.contest{
    box-shadow: initial;
  }
  
}


@media (min-width:800px){

  .cta__containertext.home--container .container__text h2{
    font-size: 2em;
  }
}

.cta__containertext.home--container .container__text{
  margin: 10% 1em;
  max-width: 60%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

@media (min-width: 480px){
  .cta__containertext.home--container .container__text{
    margin:0;
  }
}

.cta__image{
  max-width: 100%;
  display: flex;
  align-items: center;
}

.cta__imagetext {

  max-width: 90%;
  max-height: 300px;
  margin: 0 auto;
  margin-bottom: 2em;
}

@media (min-width:480px){
  .cta__imagetext{
    max-width: 100%;
    margin:0;
  }
  
}


.cta__imagetext img{
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}

.cta__image img {
    max-width: 100%;
    object-fit: contain;
    object-position: center;
}

.cta__image img.diagonal--inverted {
  width: 100%;
  object-fit: cover;
  object-position: center;
  background: #fff;
  border-radius: 5px;

}

.cta__containertext{
  flex: 1 1 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  
}

.cta__containertext.contest{
  box-shadow: -2px 0px 21px #fff;
}

.container__text--white,
.container__text{
  margin: 0 auto;
}

.container__text--white{
  color: #fff;
  max-width: 90%;
  text-align: start;
}

@media (min-width: 420px) {
  .container__text--white{
    max-width: 70%;
  }
  .home_theme .container__text--white{
    max-width: 90%;
  }

}

.container__text--white p {
  font-size: 1.2em;
  margin: 0 auto;
  margin-bottom: 1em;
  text-align: center;
  color: #444;
  line-height: 25px;
}
.container__text--white p a{
  color: #ed272a;
  
}

.container__text--white h2{
  text-align: center;
  color: #ed272a;
  line-height: 1em;
  margin: 1em 0;
  font-size: 2.4em !important;
}
.masthead .container__text--white p,
.masthead .container__text--white h2{
  color:#fff;
}
.masthead .container__text--white h2{
  margin-bottom: 0.5em;
}


@media (min-width: 420px) {
  .container__text--white h2{
    text-align: start;
  }
}

.news__sub{
  display: flex;
  flex-flow: column nowrap;
}
 .privacy_sub{
  display:flex;
  flex-flow:row;
  align-content: center;
}
.privacy_sub p,
.privacy_sub p span{
  font-family: 'PockotaReg'!important;
  font-size:0.8em!important;
  color: #333!important;  
}
.privacy_sub a{
  font-family: 'PockotaBold'!important;
  font-size:0.8em!important;
  text-decoration: none!important;
  color: #ed272a!important;
}

.contactus__form label .privacy_sub span{
  margin: 5px;
}


@media ( min-width: 950px){
 .privacy_sub{
  display:flex;
  flex-flow:row nowrap;
  align-content: center;
}
}

.privacy_sub p{
  display: flex;
  align-content: center;
  margin: 0 5px;
  font-size:1em!important;
}
.privacy_sub p > * {
  margin: 5px auto;
}


.privacy_sub p a{
  margin: 5px;
  font-size:1em!important;

}

.news__sub input{
  background: rgba(0,0,0,.1);
  border: 0;
  border-radius: 1em;
  height: 2em;
  padding: .25em 1em;
}


.news__sub input:focus{
  outline: 0;
  box-shadow: 0 0 3px 2px #ed272a;
}


.button__theme{
  cursor: pointer;
  font-size: 1.05em;
  margin-top: 1em;
  padding: 0.3125em 1.25em;
  border-radius: 10px;
  background: #ed272a;
  border: 2px solid #ed272a;
  color: #fff;
  height: 50px;
  transition: all 0.3s
}

.button__theme:hover{
  background:#ed272a;
  border: 2px solid #ffffff;
  box-shadow: 0 0px 6px 0 #4D4D4D;
  text-decoration: none;
}


.header__content div{
  max-width: 150px;
  margin: 0 1em;
  box-sizing: border-box;
  border-bottom: 4px solid #fff;
  transition: all 0.3s;
  cursor:pointer;

}

.header__content div.active{
  border-bottom: 4px solid #ed272a;
  /*box-shadow: 0 0px 6px 0 #4D4D4D;*/

}
.header__content div:hover{
  border-bottom: 4px solid #ed272a;
  /*box-shadow: 0 0px 6px 0 #4D4D4D;*/
}
.header__content div img{
  max-width: 100%;
  object-fit: contain;
  object-position: center;

}
.menu__text{
  text-align: center;
  margin: 2em auto;
  color: #979797;
}

.produto{
  width: 9.375em;
  margin: 1em auto;
}

.produto__img{
  margin: 0 auto
}

.produto__img img {
    max-width: 100%;
    object-fit: contain;
    object-position: center;
    height: 200px;
    margin: 0 auto;
}
.produto__button .button__theme{
  width: 100%;
  margin: 1em auto;
  font-size: 1em;
}

.footer{
  background: #ed272a;
}
.footer .copyright{
  text-align: center;
  color: #fff;
  padding: 10px;
}
.footer__container{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  max-width: 90%;
  align-items: flex-start;
  margin: 0 auto;
  padding:3em ;
}
.footer__container>div{
	width:30%;
	text-align:center;
	color:#fff;
}

.footer__container .terms .both{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  max-width: 55%;
  margin: 0 auto;
}

.footer__container .terms a{
	color:#fff;
	text-decoration:none;
  margin:10px auto;
}

.footer__container .terms a:hover{
	color:#fff;
	text-decoration:underline;
}
.footer__container .terms .socialmedia a{
  color: #fff;
}

@media (max-width: 792px){
  .footer__container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    max-width: 90%;
    align-items: center;
    margin: 0 auto;
    padding: 3em;
  }
  .footer__container > div{
    max-width: 100%;
    width: initial;
    text-align: center;
    color: #fff;
    margin: 1em auto;
  }
  .footer__container .terms .both{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
  }
  .footer__container .terms .both a{
   margin: 10px auto;
  }
}

.both .headingfooter{
  padding-bottom: 0.70em;
}

.both p span{
  line-height: 28px;
}

.socialmedia{
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.socialmedia li {
  border-radius: 1.5625em;
  margin: 0 5px;
  max-width: 45px;
}
.socialmedia li img{
	max-width: 100%;
  object-fit: contain;
  object-position: center;
}
.fa-facebook-f{
  padding: 0 2px;
}


.menu__comprar{
  width: 90%;
  margin: 1.5em auto;

}

.menu-icon{
  width: 100%;
  text-align: center;
}

.menuopener{
  text-align: center;
  z-index: 7;
  transition: all 0.3s;
  cursor: pointer;
}

.menuopener.open{
  transform:rotate(180deg);
}

.menu__comprar--container{
  margin: 0 auto;
  max-width: 90%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height:1px;
  opacity: 0;
  transition: all 0.4s;
  z-index: 19;
  
}
.menu__comprar--container.open{
  height: 200px;
  opacity: 1;
  margin: 3em auto;

}
@media (min-width:768px){
  .menu__comprar--container{
    margin: 0 auto;
    max-width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .menu__comprar--container.open{
    height: 60px;
    opacity: 1;
    margin: 0 auto;

  }
}

@media (min-width:890px){
  .menu__comprar--container{
    margin: 0 auto;
    max-width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .menu__comprar--container.open{
    height: 100px;
    opacity: 1;
    margin: 0 auto;

  }
}

.menu__comprar--container a{
  text-decoration: none;
  color: #333;
}


.menu__comprar--container li{
  cursor: pointer;
  transition: all 0.4s;
  position: relative;
  height: 20px;
  padding: 9px 20px;
  font-family: 'PockotaBold';
  visibility: hidden;
  display: none;

}
.menu__comprar--container.open li{
  visibility: visible;  
  display: block;
 

  
}

.menu__comprar--container li:after{
  content: "";
  width: 0%;
  position: absolute;
  bottom: 0%;
  margin-top: 2px;
  left: 0;
  height: 3px;
  background: #ed272a;
  transition: all 0.4s;
  opacity:0;
}

.menu__comprar--container li:hover:after{
  opacity:1;
  width: 100%;
}

.menu__comprar--container li.active{
  background: #ed272a;
  border-radius: 15px;
  color: #fff;
  font-size: 1.10em;
  transition: all 0.4s;
}
.menu__comprar--container li.active:hover:after{
  width:0;
}
.menu-item-in{
  width: 100%;
}

 .goback{
  display: flex;
  justify-content: center;

}
.goback a{
 text-decoration: none;
 color: #fff;
 width: 25vw;

}

@media (max-width: 640px){
 .goback a{
   width: 35vw;
 
  }
}
@media (max-width: 360px){
 .goback a{
   width: 50vw;
  }
}



/* Bolinha notificadora de itens */
.menus__carrinho.icon{
  position: relative;
}

.blog.icon{
  width: 35px;
  border-radius: 100%;
  text-align: center;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog.icon a{
  text-decoration: none;
}

.blog.icon a span{
  color: #cacaca;
  font-size: 16px;
  font-weight: bold;
}

span.bolinha{
  position: absolute;
  bottom: 1%;
  padding: 1px 5px;
  background: #fff;
  border-radius: 100%;
  color: #ed272a;
  right: 0;
  font-size: 12px;
}

p.warningtext{
  text-align: center;
  background-color: rgb(190, 214, 0);
  font-weight: bold;
  color: rgb(255, 255, 255);
  max-width: 50%;
  margin: 0 auto;
  padding: 1em;
  border-radius: 15px;
}
.hero.redbackground{
 margin-bottom: 0;
}

.hero.redbackground .cta__ {
  background-color: #ed272a;
  display: flex;
  flex-flow: column nowrap;

}



.hero.redbackground .cta__ .cta__containertext .container__text--white h2{
  color: #fff;
}
.hero.redbackground .cta__ .cta__containertext .container__text--white p{
  color: #fff;
}
.hero.redbackground .blockup{
  height: 0;
  background-color: #ed272a;
}
.hero.redbackground .blockdown{
  height: 4em;
  background-color: #ed272a;
}

@media (min-width: 420px) {
  .hero.redbackground .blockup{
    height: 4em;
  }
}
@media (min-width: 900px) {
  .hero.redbackground .cta__ .cta__image {
    flex: 40%;
    justify-content: center;
    align-self: self-start;
    height: 100%;
  }

  .hero.redbackground .cta__ .cta__image img{
    width: initial;
    height: 100%;
  }

  .hero.redbackground .cta__ .cta__containertext .container__text--white h2{
    margin-top: 0;
  }

  .hero.redbackground .cta__ .cta__containertext .container__text--white p{
    font-size: 1.3em;
    line-height: 27px;
  }
}
@media (min-width: 900px) {
  .hero.redbackground .cta__ {
    background-color: #ed272a;
    display: flex;
    flex-flow: row;
    justify-content: center;
  }
}



.buttons-row a{
  margin: 0 1em;
}

.buttons-row a .button__theme{
  background-color: #ffffff;
  color: #ed272a;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 2px #000;
  width: 180px;
}

.buttons-row a .button__theme:hover{
  border:3px solid #ed272a;
}
.buttons-row a:last-child .button__theme{
  background-color: #ed272a00;
  color: #ffffff;
  border:2px solid #ffffff;
  box-shadow: 0 0px 3px #ffffff00;
}

.buttons-row a:last-child .button__theme:hover{
  border:2px solid #ffffff;
  box-shadow: 0 0px 3px #000000;
}


.buttons-row a{
  margin: 0 1em;
}

.hero.redbackground .buttons-row a .button__theme{
  background-color: #fff;
  color: #ed272a;
  border: 3px solid #fff;
  box-shadow: 0px 0px 2px #000;
  width: 180px;
  font-size: 1.3em;
  padding: initial;
  transition: 0.3s all;
}
.hero.redbackground .buttons-row a .button__theme:hover{
  border: 3px solid #ed272a;
  box-shadow: 0px 0px 5px #000;
}



.hero.redbackground .container__text--white{
  max-width: 90%;
}



.buysec .container__text--white .buttons-row a{
  margin: 0 1em;
}
.buysec .container__text--white .buttons-row a .button__theme{
  background-color: #ed272a;
  color: #fff;
  border:3px solid #ed272a;
  box-shadow: 0px 0px 2px #000;
}
.buysec .container__text--white .buttons-row a .button__theme:hover{
  border:3px solid #fff;
}
.buysec .container__text--white .buttons-row a:last-child .button__theme{
  background-color: #ed272a00;
  color: #ed272a;
  border:2px solid #ed272a;
}
.buysec .container__text--white .buttons-row a:last-child .button__theme:hover{
  border:2px solid #ed272a;
  box-shadow: 0 0px 4px #000000;
}

@media (max-width: 445px) {
  .buttons-row{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }


}

@media (max-width: 420px ) {
  .certs .cta__ .cta__containertext {
    order: 2;
  }
  .certs .cta__ .cta__image{
    order:1;
  }
}

.item-list{

  max-width: 900px;
  margin: 0 auto ;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  align-items: center;
}


@media (min-width: 420px){
  .item-list{
    height: 100%;
    padding: 2em 0;
    max-width: 900px;
    margin: 0 auto ;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }
}

@media ( min-width: 630px){
  .item-list{
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* lista dos produtos  */
.itens-section{
  padding-bottom: 3em;
}
.itens-section .title {
  text-align: center;
  margin: 1em auto;
}
.itens-section .title h2{
  font-size: 2.4em!important;
  margin-bottom: 1em;
}
@media (min-width: 420px) {
  .itens-section .title h2{
    font-size: 3em!important;
  }
}

.items_content{
  max-width: 200px;
  margin: 0 auto;
}
.items_content .item{
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 3px #fff;
  transition: 0.3s all;
}
.items_content .item:hover {
  box-shadow: 0 0 3px #666;
}
.items_content .item:hover a {
  text-decoration: none;
  color: #fff;
}

.items_content .item img{
  max-width: 100%;
  object-fit: cover;
  object-position: center;
}

.item_link{
  margin: 1em auto;
  padding: 3px 15px;
  background: #ed272a;
  border: 2px solid #ed272a;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s;
}
.item_link:hover{
  background: #ed272a;
  border: 2px solid #ffffff;
  box-shadow: 0 0px 6px 0 #4D4D4D;
  color: #ffffff;

}
.item_link:hover a{
  background: #ed272a;
  border: 2px solid #ffffff;
  box-shadow: 0 0px 6px 0 #4D4D4D;
  color: #ffffff;
  text-decoration: none;
}

.item a {
  text-decoration: none;
  font-size: 1em;
  color: #fff;
  font-family: "PockotaBold";
}

@media (min-width: 900px) {
  .home_theme .hero.firstsec .cta__containertext .container__text--white p{
    max-width: 100%;

  }
  .container__text--white p{
    text-align: left;
    line-height: 34px;
    font-size: 1.5em;
  }
}

.list-cert{
  list-style: initial;
  padding-left: 2em;
  font-size: 0.8em;
}

.item a{
  position: relative;
  display: inline-block;
}

.item a .discounts{
  position: absolute;
  top:-3%;
  right:-8px;
  display: flex;
  flex-flow: column nowrap;
  background: #ed272a;
  padding: 10px 8px;
  border-radius: 100px;

}

.item a .discounts span{
  font-size: 12px;
  transition:  0.4s all;
}

.item a .discounts .scratchy{
  text-decoration: line-through;
}
.item:hover a .discounts span{
  font-size: 16px;
}



/* CAMPANHAS */

.ninja {
  align-items: flex-start;
  text-align: initial;
  height: 100%;
}

.ninja .container__text--white{
  margin: 2em;

}

.masthead .ninja .container__text--white h2{
  margin-bottom: 4em;
}