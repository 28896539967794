@font-face {
    font-family: PockotaReg;
    src: url("../fonts/Pockota-Regular.otf") format("opentype");
}

@font-face {
    font-family: PockotaBold;
    src: url("../fonts/Pockota-Bold.otf") format("opentype");
}

@font-face {
    font-family: TheHistoria;
    src: url("../fonts/TheHistoriaDemo.ttf") format("truetype");
}
